import React from "react";
import { Container, Row, Col, Navbar } from "react-bootstrap";
import Footer from "./Footer/Footer";
import Button from "react-bootstrap/Button";

const DataSources = () => {
  return (
    <div>
      <Navbar bg="info" expand="lg">
        <Navbar.Brand
          href="/"
          style={{
            marginLeft: "20px",
            backgroundColor: "lightblue",
            borderRadius: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          USA Cost Watch
        </Navbar.Brand>
      </Navbar>
      <Container
        style={{
          height: "80vh",
        }}
      >
        <Row>
          <Col>
            <h1>Data Sources</h1>
            <ul>
              <li>
                Income and Housing Estimates: U.S. Census Bureau,{" "}
                <a href="https://www.census.gov/programs-surveys/acs/data.html">
                  2024 American Community Survey 5-Year Estimates
                </a>
                .
              </li>
              <li>
                City Locations:{" "}
                <a href="https://simplemaps.com/data/us-cities">
                  https://simplemaps.com/data/us-cities
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="primary" href="\">
              Home
            </Button>
          </Col>
        </Row>
      </Container>
      <Footer></Footer>
    </div>
  );
};

export default DataSources;
