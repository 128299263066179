// import averageRentLegend from "./US_average_percent_rent_Legend.json";
import homeLegend from "./US_home_Legend.json";
import incomeLegend from "./US_income_Legend.json";
import rentLegend from "./US_rent_Legend.json";

class StatisticSource {
  constructor(
    statistic,
    description,
    shortDescription,
    mapStyleLink,
    legendList,
    preUnit = "",
    postUnit = ""
  ) {
    this.mapStyleLink = mapStyleLink;
    this.legendList = legendList;
    this.statistic = statistic;
    this.description = description;
    this.shortDescription = shortDescription;
    this.preUnit = preUnit;
    this.postUnit = postUnit;
  }

  toJSON() {
    return {
      mapStyleLink: this.mapStyleLink,
      legendList: this.legendList,
      statistic: this.statistic,
      description: this.description,
      shortDescription: this.shortDescription,
      preUnit: this.preUnit,
      postUnit: this.postUnit,
    };
  }
}

export const statistics = {
  //   average_percent_rent: new StatisticSource(
  //   "average_percent_rent",
  //   "Average Percent Income Spent on Rent",
  //   "Percent",
  //   "mapbox://styles/cwhitman/clew2degb00ba01oa82id17nz",
  //   averageRentLegend,
  //   "",
  //   "%"
  // ).toJSON(),
  home: new StatisticSource(
    "home",
    "Median Home Price",
    "Price",
    "mapbox://styles/cwhitman/clx9o535h044a01qm7cqqdwq4",
    homeLegend,
    "$",
    ""
  ).toJSON(),
  income: new StatisticSource(
    "income",
    "Median Yearly Income",
    "Income",
    "mapbox://styles/cwhitman/clx9o5797044b01qmd18y0fhh",
    incomeLegend,
    "$",
    ""
  ).toJSON(),
  rent: new StatisticSource(
    "rent",
    "Median Monthly Rent Cost",
    "Rent",
    "mapbox://styles/cwhitman/clx9o1qha03xd01qpdp8ifey4",
    rentLegend,
    "$",
    ""
  ).toJSON(),
};
