import { useSelector } from "react-redux";
import { selectStatistic } from "../StateManagement/statisticSourceSlice";
import MapLegendArrow from "./MapLegendArrow";

const MapLegendMobile = () => {
  const statistic = useSelector(selectStatistic);

    return (
      <div>
      <table style={{ backgroundColor: "#fff", borderRadius: "10px" }}>
        <thead>
          <th style={{ textAlign: "center", fontSize: "11px" }}>
            {statistic.shortDescription}
          </th>
        </thead>
        <tbody>
          <tr>
            <td width="60">
              <MapLegendArrow></MapLegendArrow>
            </td>
          </tr>
          
        </tbody>
      </table>
      <div style={{ fontSize: "10px"}}> usacostwatch.com </div>
      </div>
    );
};

export default MapLegendMobile;
